import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { NumberDirective } from './titlecase/titlecase.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective, NumberDirective],
  exports: [RippleEffectDirective, FeatherIconDirective, NumberDirective]
})
export class CoreDirectivesModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";


@Injectable()
export class UploadService  {
  public rows: any;
  public onUserListChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  public uploadFileUser(fd, fileType, fileToAWS) {
		return this._httpClient.post<any>(`/api/file/user-excel?fileType=${fileType}&${fileToAWS}`, fd);
	}

  public uploadFileCountry(fd, fileType, fileToAWS) {
		return this._httpClient.post<any>(`/api/file/country-excel?fileType=${fileType}&${fileToAWS}`, fd);
	}

  public uploadFileCompany(fd, fileType, fileToAWS) {
		return this._httpClient.post<any>(`/api/file/company-excel?fileType=${fileType}&${fileToAWS}`, fd);
	}

  public uploadFileDepartment(fd, fileType, fileToAWS) {
		return this._httpClient.post<any>(`/api/file/department-excel?fileType=${fileType}&${fileToAWS}`, fd);
	}

  public uploadFileLocation(fd, fileType, fileToAWS) {
		return this._httpClient.post<any>(`/api/file/location-excel?fileType=${fileType}&${fileToAWS}`, fd);
	}

  public exportStockDetails() {
		return this._httpClient.get<any>(`/api/request-detail/report`);
	}

  public downloadFile(path) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});
    return this._httpClient.get(`/api/physical-stock/download-image?key=${path}`, { headers : headers,responseType : 
      'blob' as 'json'});
  }
}

import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

function isAllowed(moduleName) {
  if (localStorage.getItem('menuPermissions')) {
    if (JSON.parse(localStorage.getItem('menuPermissions')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }  
}

// function checkMaster(){
// let u = JSON.parse(localStorage.getItem("userData"));
// if(u){
//   console.log(u['userData']['email'], "JAJAJAJ");
//   if(u['userData']['email'] == 'bhavna.sen@godrejcp.com'){
//     return true
//   } else{
//     return false
//   }
// }
// }

function isAllowedParent(moduleName) {
  if (localStorage.getItem('menuPermissionsParent')) {
    if (JSON.parse(localStorage.getItem('menuPermissionsParent')).includes(moduleName)) {
      let u = JSON.parse(localStorage.getItem("userData"));
      if(u){
      console.log(u['userData']['email'], moduleName, "JAJAJAJ");
      if(u['userData']['email'] == 'bhavna.sen@godrejcp.com' && moduleName == 'masters'){
      return true
      } else{
      return false
      }
      } else {
      return false
      }
    } else {
      return true
    }
  } else {
    return true
  }
}
export const menu = [
  {
    id: 'MASTERS',
    type: 'section',
    title: 'Masters',
    // translate: 'MENU.APPS.SECTION',
    icon: 'package',
    hidden: isAllowedParent('masters'),
    children: [
      {
        id: 'approval-user',
        title: 'Approval Users',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/approval-user',
        hidden: isAllowed('approval-user-master'),
      },
      {
        id: 'roles',
        title: 'Role Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/roles',
        hidden: isAllowed('role-master'),
      },
      {
        id: 'product',
        title: 'Product Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/product',
        hidden: isAllowed('product-master'),
      },
      {
        id: 'price',
        title: 'Price Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/price',
        hidden: isAllowed('price-master'),
      },
      {
        id: 'customer',
        title: 'Customer Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/customer',
        hidden: isAllowed('customer-master'),
      },
      {
        id: 'sku',
        title: 'SKU Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/sku',
        hidden: isAllowed('sku-master'),
      },
      {
        id: 'app',
        title: 'APP Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/app',
        hidden: isAllowed('app-master'),
      }
    ]
  },
  {
    id: 'My Requests',
    type: 'item',
    title: 'My Requests',
    icon: 'package',
    url: 'apps/transactions/my-requests',
    hidden: isAllowed('my-requests')
  },
  {
    id: 'import-leads',
    title: 'Import Leads',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/import-leads',
    hidden: isAllowed('import-leads'),
  },
  {
    id: 'cfa-approval',
    title: 'My Approval',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/cfa-approval',
    hidden: isAllowed('cfa-approval'),
  },
  {
    id: 'lead-details',
    title: 'My Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/lead-details',
    hidden: isAllowed('lead-details')
  },
  {
    id: 'my-lead-details',
    title: 'Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/asm-lead-details',
    hidden: isAllowed('asm-lead-details')
  },
  {
    id: 'lead-details',
    title: 'Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/sales-lead-details',
    hidden: isAllowed('sales-lead-details')
  },
  {
    id: 'lead-details',
    title: 'Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/fo-lead-details',
    hidden: isAllowed('fo-lead-details')
  },
  {
    id: 'lead-details',
    title: 'Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/trainer-lead-details',
    hidden: isAllowed('trainer-lead-details')
  },
  {
    id: 'admin-lead-details',
    title: 'All Lead Details',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/admin-lead-details',
    hidden: isAllowed('admin-lead-details')
  },
  {
  id: 'brand-approval',
  title: 'All Other Requests',
  type: 'item',
  icon: 'package',
  url: 'apps/transactions/brand-approval-all',
  hidden: isAllowed('my-approval')
  },
  {
    id: 'stock-statements',
    title: 'Stock Statement',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/stock-statements',
    hidden: isAllowed('stock-statements')
  },
  {
    id: 'stock-summary-report',
    title: 'Stock Summary Report',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/stock-summary-report',
    hidden: isAllowed('stock-summary-report')
  },
  {
    id: 'stock-audit-report',
    title: 'Stock Audit Report',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/stock-audit-report',
    hidden: isAllowed('stock-audit-report')
  },
  {
    id: 'agency-stocks',
    title: 'Opening Stock',
    type: 'item',
    icon: 'package',
    url: 'apps/transactions/agency-stocks',
    hidden: isAllowed('agency-stocks')
  },
  // {
  //   id: 'stock-transfer-module',
  //   title: 'Stock Transfer Module',
  //   type: 'item',
  //   icon: 'package',
  //   url: 'apps/transactions/stock-transfer-module',
  //   hidden: isAllowed('stock-transfer-module')
  // },
  {
    id: 'Stock Transfer Module',
    type: 'section',
    title: 'Stock Transfer Module',
    // translate: 'MENU.APPS.SECTION',
    icon: 'package',
    hidden: isAllowedParent('stock-requests'),
    children: [
      {
        id: 'stock-transfer-module',
        title: 'Stock Transfer',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/stock-transfer-module',
        hidden: isAllowed('stock-transfer-module'),
      },
      {
        id: 'stock-transfer-request-pending',
        title: 'Pending Transfer Requests',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/stock-transfer-request-pending',
        hidden: isAllowed('stock-transfer-request'),
      },
      {
        id: 'stock-transfer-request',
        title: 'Approved/Rejected Transfer Requests',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/stock-transfer-request',
        hidden: isAllowed('stock-transfer-request'),
      }
    ]
  }
  // {
  //   id: 'stock-transfer-request',
  //   title: 'Stock Transfer Request',
  //   type: 'item',
  //   icon: 'package',
  //   url: 'apps/transactions/stock-transfer-request',
  //   hidden: isAllowed('stock-transfer-request')
  // },
  // children: [
  //   {
  //     id: 'my-requests',
  //     title: 'My Stock Requests',
  //     type: 'item',
  //     icon: 'message-square',
  //     url: 'apps/transactions/my-requests',
  //     hidden: isAllowed('my-requests'),
  //   },
  //   {
  //     id: 'new-stock-request',
  //     title: 'New Stock Request Form',
  //     type: 'item',
  //     icon: 'grid',
  //     url: 'apps/transactions/new-stock-request',
  //     hidden: isAllowed('new-stock-request'),
  //   },

  //   {
  //     id: 'cfa-approval',
  //     title: 'My Approval',
  //     type: 'item',
  //     icon: 'message-square',
  //     url: 'apps/transactions/cfa-approval',
  //     hidden: isAllowed('cfa-approval'),
  //   },
  //   {
  //     id: 'brand-approval',
  //     title: 'My Approval',
  //     type: 'item',
  //     icon: 'grid',
  //     url: 'apps/transactions/brand-approval',
  //     hidden: isAllowed('brand-approval'),
  //   },]
];



import { NgModule } from '@angular/core';

import { CoreMenuModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';

import { HorizontalMenuComponent } from 'app/layout/components/menu/horizontal-menu/horizontal-menu.component';
import { UploadService } from 'app/main/apps/upload-service/upload.service';

@NgModule({
  declarations: [HorizontalMenuComponent],
  imports: [CoreMenuModule, CoreCommonModule],
  providers: [UploadService ],
  exports: [HorizontalMenuComponent]
})
export class HorizontalMenuModule {}
